import React from "react"
import {
  Section,
  Container,
  Column,
  Columns,
  Button,
  Content,
} from "bloomer"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import runasData from "../../data/oraculo/runas/runas.json"

export default class Runas extends React.Component {
  state = {
    selected: []
  }

    handleClick = event => {
        let random = runasData;

        for (let i = random.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [random[i], random[j]] = [random[j], random[i]];
        }
        
        this.setState({selected:random.slice(0,3)})
    }

  render() {
    const {selected} = this.state;
    var items = selected.map(function(runa, i){
        if(runa['id']){
            const image = require("../../data/oraculo/runas/images/"+runa["name"].toLowerCase()+".png")
            return (
            <Content key={i} className="media">
                <div className="media-left">
                    <img src={image} alt={runa["name"]}/>
                </div>
                <div className="media-content">
                    <h2>Nome: {runa["name"]}</h2>
                    <p>Título: {runa["title"]}</p>
                    <p>Palavra: {runa["word"]}</p>
                    <p>Advertência: {runa["alert"]}</p>
                    <p>Mensagem: {runa["message"]}</p>
                    <p> {runa["text"]}</p>
                </div>
            </Content>
            )
        }else{
            return ""
        }
        
    });
 
    return (
      <Layout>
        <SEO title="Runas - Estrelaguia Guia" />
              <Content>
                <h1>Runas</h1>
                <p>A Numerologia Cabalística é uma das ciências esotéricas mais antigas que existe. Com a Numerologia, você pode descobrir mais sobre sua vida pessoal, amorosa e financeira, utilizando as letras de seu nome e sua data de nascimento. Para saber mais sobre sua personalidade, preencha seu nome completo e data de nascimento nos campos ao lado.</p>
                </Content>
                <Content>
                {items}
                <Button className="is-primary" onClick={() => this.handleClick()} > Sortear</Button>
              </Content>
      </Layout>
    )
  }
}


